/*! `Custom` Bootstrap 4 theme */
$success: #28a745;

@import "../node_modules/bootstrap/scss/bootstrap";
// Body
$body-bg: #ffffff;

// $fa-font-path: "../node_modules/font-awesome/fonts";
@import "../node_modules/font-awesome/scss/font-awesome.scss";

@import url(https://fonts.googleapis.com/css?family=Lato:200,300,400,700);
$font-family-base:Lato;
$headings-font-family:Lato;

// Typography
$font-size-base: 12px; // this changes the font-size throughout bootstrap
// $font-size-base: 0.9rem;
$line-height-base: 1.6;



$enable-grid-classes:false;
$primary:#68c4fd;
$secondary:#a0b7bc;
$success:#43dabb;
$danger:#fd998b;
$info:#76d5f0;
$warning:#f6cc78;
$light:#eef0f2;
$dark:#3c4055;
$enable-gradients:false;
$spacer:1rem;
$border-width:1px;
$btn-border-radius:.25rem;

// regular style toast
@import "../node_modules/ngx-toastr/toastr.css";

.toast-container .ngx-toastr {
  width: auto !important;
  max-width: 640px !important;
}
// bootstrap style toast
@import "../node_modules/ngx-toastr/toastr-bs4-alert.scss";


/* Sortable table */
.page-link:focus {
  box-shadow: unset !important;
}

th[sortable] {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  margin-left: -12px;
}

th[sortable].desc:after, th[sortable].asc:after {
  content: '';
  display: inline-block;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4wcKFSYUQnDJOgAAAI5JREFUOMvt1DEOQDAUxvG/RsQgBoNdJ0cQi1M5khOYxOQWYqjdJVgMItIqJrz59Zfvpe1zZmaeLMHD9UHQNTXIRAZACVRqVIOp39Hd8oo1QA5MQKFG1V8aeYcBxEAnE5laJ1yxFsgOzmiTCkvMmFRYYkZUbLDwJKZFtwlrC2yPRkegf+NzeKfe4b8cXgIuu14migtPqhYAAAAASUVORK5CYII=') no-repeat;
  color: black;
  background-size: 12px;
  width: 12px;
  height: 12px;
  margin-right: -4px;
  margin-top: 3px;
}

th[sortable].desc:after {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  margin-top: 7px;
}

th, td {
  font-size: 14px;
  white-space: unset !important;
  text-overflow: unset !important;
}
.th-sm {
  font-size: 14px;
}
.pagination {
  font-size: 0.9rem;
}


/* Filtering table */
span.ngb-highlight {
  background-color: yellow;
}

body {
  background: #f0f0f0;
}


// Add SASS theme customizations here..

/* blink */
@keyframes blink {
  50% {
    opacity: .0;
  }
}
@-webkit-keyframes blink {
  50% {
    opacity: .0;
  }
}

.blink {
  animation: blink 1s step-start 0s infinite;
  -webkit-animation: blink 1s step-start 0s infinite;
}

.inline {
  display: flex;
  flex-flow: row wrap;
}

.selected {
  background: #b6ddfc !important;
}

.w30 {
  min-width: 30px;
}

@media only screen and (max-width: 840px) {
  .collapsible {
    display:none;
  }
}

a:not([href]):not(.nav-pills .nav-link.active) {
  color: #007bff !important;
}

.nav-pills .nav-link.active {
  color: #ffffff !important;
}
